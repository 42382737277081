.layout {
  min-height: 100vh !important;

  &__logo {
    a {
      height: 60px;
      color: white;
      text-decoration: none;
      display: flex;
      padding-left: 24px;
      align-items: center;
      font-size: 30px;

      transition: 0.3s ease;
      &:hover {
        color: #1890ff;
      }
    }
  }
}