// Public utils classes, can use from anywhere.
@import "./vars";

$dims: ((sm, $bp-sm), (md, $bp-md), (lg, $bp-lg));

// Content alignment
// ------------------------------------------

.u-align-left {
  float: left;
}

.u-align-right {
  float: right;
}

.u-align-center {
  margin: auto;
}

.u-flex-left {
  justify-content: flex-start;
}

.u-flex-right {
  justify-content: flex-end;
}

.u-flex-center {
  justify-content: center;
}

@each $dim-name, $dim-val in $dims {
  // More than
  @media (min-width: $dim-val) {
    .u-flex-left-#{$dim-name} {
      justify-content: flex-start;
    }

    .u-flex-right-#{$dim-name} {
      justify-content: flex-end;
    }

    .u-flex-center-#{$dim-name} {
      justify-content: center;
    }
  }
}

// Text alignment
// ------------------------------------------

@each $dir in left, right, center {
  .u-text-align-#{$dir} {
    text-align: $dir;
  }
}

@each $dir in left, right, center {
  @each $dim-name, $dim-val in $dims {
    @media (min-width: $dim-val) {
      .u-text-align-#{$dir}-#{$dim-name} {
        text-align: $dir;
      }
    }
  }
}

// Visibility
// ------------------------------------------

.u-hidden {
  display: none !important;
}

.u-invisible {
  visibility: hidden !important;
}

@each $dim-name, $dim-val in $dims {
  // More than
  @media (min-width: $dim-val) {
    .u-hidden-mt-#{$dim-name} {
      display: none !important;
    }
  }

  // Less than
  @media (max-width: $dim-val - 1px) {
    .u-hidden-lt-#{$dim-name} {
      display: none !important;
    }
  }
}

// Borders
// ------------------------------------------

$block-border-color: #e3e7eb;

@each $side in (top, bottom, left, right) {
  .u-border-#{$side} {
    border-#{$side}: 1px solid $block-border-color;
  }

  .u-border-#{$side}-none {
    border-#{$side}: none;
  }
}

@each $side in (top, bottom, left, right) {
  @each $name, $size in $dims {
    @media (min-width: $size) {
      .u-border-#{$side}-none-#{$name} {
        border-#{$side}: none;
      }

      .u-border-#{$side}-#{$name} {
        border-#{$side}: 1px solid $block-border-color;
      }
    }
  }
}

// Block flow
// ------------------------------------------

.u-block {
  display: block !important;
}

.u-inline-block {
  display: inline-block;
}

.u-pos-r {
  position: relative;
}

@each $name, $size in $dims {
  @media (min-width: $size) {
    .u-block-#{$name} {
      display: block;
    }
  }
}

@each $dim-name, $dim-val in $dims {
  @media (min-width: $dim-val) {
    .u-inline-block-#{$dim-name} {
      display: inline-block;
    }
  }
}

// Flex
// ------------------------------------------

.u-flex {
  display: flex;
}

@each $name, $size in $dims {
  @media (min-width: $size) {
    .u-flex-#{$name} {
      display: flex;
    }
  }
}

// Margins and Paddings
// ------------------------------------------

$offset: 10px;

@for $i from 0 through 9 {
  .u-m-#{$i} {
    margin: $offset * $i;
  }
  .u-mx-#{$i} {
    margin-left: $offset * $i;
    margin-right: $offset * $i;
  }
  .u-my-#{$i} {
    margin-top: $offset * $i;
    margin-bottom: $offset * $i;
  }
  .u-mb-#{$i} {
    margin-bottom: $offset * $i;
  }
  .u-mt-#{$i} {
    margin-top: $offset * $i;
  }
  .u-ml-#{$i} {
    margin-left: $offset * $i;
  }
  .u-mr-#{$i} {
    margin-right: $offset * $i;
  }

  .u-p-#{$i} {
    padding: $offset * $i;
  }
  .u-px-#{$i} {
    padding-left: $offset * $i;
    padding-right: $offset * $i;
  }
  .u-py-#{$i} {
    padding-top: $offset * $i;
    padding-bottom: $offset * $i;
  }
  .u-pb-#{$i} {
    padding-bottom: $offset * $i;
  }
  .u-pt-#{$i} {
    padding-top: $offset * $i;
  }
  .u-pl-#{$i} {
    padding-left: $offset * $i;
  }
  .u-pr-#{$i} {
    padding-right: $offset * $i;
  }
}

@for $i from 0 through 9 {
  @each $name, $size in $dims {
    @media (min-width: $size) {
      .u-m-#{$i}-#{$name} {
        margin: $offset * $i;
      }
      .u-mx-#{$i}-#{$name} {
        margin-left: $offset * $i;
        margin-right: $offset * $i;
      }
      .u-my-#{$i}-#{$name} {
        margin-top: $offset * $i;
        margin-bottom: $offset * $i;
      }
      .u-mb-#{$i}-#{$name} {
        margin-bottom: $offset * $i;
      }
      .u-mt-#{$i}-#{$name} {
        margin-top: $offset * $i;
      }
      .u-ml-#{$i}-#{$name} {
        margin-left: $offset * $i;
      }
      .u-mr-#{$i}-#{$name} {
        margin-right: $offset * $i;
      }

      .u-p-#{$i}-#{$name} {
        padding: $offset * $i;
      }
      .u-px-#{$i}-#{$name} {
        padding-left: $offset * $i;
        padding-right: $offset * $i;
      }
      .u-py-#{$i}-#{$name} {
        padding-top: $offset * $i;
        padding-bottom: $offset * $i;
      }
      .u-pb-#{$i}-#{$name} {
        padding-bottom: $offset * $i;
      }
      .u-pt-#{$i}-#{$name} {
        padding-top: $offset * $i;
      }
      .u-pl-#{$i}-#{$name} {
        padding-left: $offset * $i;
      }
      .u-pr-#{$i}-#{$name} {
        padding-right: $offset * $i;
      }
    }
  }
}
