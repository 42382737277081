@import "~antd/dist/antd.css";
@import "./normalize.scss";
@import "./utils.scss";
@import "./overwrite.scss";

.container {
  max-width: 1140px;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;

  &.left {
    margin-left: 0;
    margin-right: 0;
  }
}
